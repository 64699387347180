<template>
  <header class="topnavbar-wrapper nav-header-fixed">
        <nav role="navigation" class="navbar topnavbar">
            <div class="navbar-header">
                <button type="button" data-toggle="collapse" data-target=".navbar-collapse" class="navbar-toggle">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a href="#/" class="navbar-brand">
                        <div class="company-name brand-logo brand-logo-text" :title="infoUser.entreprise=='gts'?'GTS AFRIQUE PREMIUM':'SUNUTRACK PREMIUM'">
                            {{infoUser.entreprise=='gts'?"GTS AFRIQUE":"SUNUTRACK"}} PREMIUM
                        </div>

                </a>
            </div>
            <div class="navbar-collapse collapse">
                <ul class="nav navbar-nav navbar-nav-first top-header-navbar">
                    <li></li>
                    <li>
                        <div href="javascript:;" class="setup-add multi-col-dropdown dropdown" title="Quick Links">
                            <div class="dropdown-toggle" data-toggle="dropdown">
                                <em class="zmdi zmdi-plus"></em>
                            </div>
                            <div class="dropdown-menu">
                                <ul class="list-inline">
                                    <li>
                                        <ul class="list-unstyled">
                                                <li><a href="#/Vehicles/Vehicle/Add"><em class="icon-compass"></em>Vehicle</a></li>
                                                                                            <li><a href="#/Setup/POIZone/Get"><em class="icon-map"></em>Poi / GeoFence</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul class="list-unstyled">
                                                <li><a href="#/Vehicles/AlertSettings/Get"><em class="icon-bell"></em>Alert Settings</a></li>
                                                                                            <li><a href="#/Setup/Driver/Get"><em class="icon-people"></em>Driver</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <!---->
                    <li class="only-tab-device">
                        <div href="javascript:;" class="setup-add multi-col-dropdown dropdown setup-add-tablet" title="Other Links">
                            <div class="dropdown-toggle" data-toggle="dropdown">
                                <em class="icon-options-vertical f-16"></em>
                            </div>
                            <div class="dropdown-menu">
                                <ul class="list-unstyled">
                                        <li>
                                            <a href="#/Dashboards/Enterprise" title="Analytics">
                                                <em class="icon-grid"></em>  <span>Analytics</span>
                                            </a>
                                        </li>
                                    <li>
                                        <a href="#/Dashboards/Dashboard/Index" title="Dashboard" class="navbar-active">
                                            <em class="icon-speedometer"></em>  <span>Dashboard</span>
                                        </a>
                                    </li>
                                        <li>
                                            <a href="#/Gps/Tracking/Index" title="Workspace" class="">
                                                <em class="icon-map"></em> <span>Workspace</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#/Dashboards/BirdEyeView/Index" title="Birds Eye View" class="">
                                                <em class="icon-eye"></em> <span>Birds Eye View</span>
                                            </a>
                                        </li>
                                                                                                                                                                                                                    </ul>
                            </div>
                        </div>
                    </li>
                    <!---->
                        <li class="hide-tab-device">
                            <a href="/" title="Temps Réel" :class="page=='temps_reel'?'navbar-active':''">
                                Temps Réel
                            </a>
                        </li>
                    <li class="hide-tab-device">
                        <a href="/dashboard" title="Dashboard" :class="page=='dashboard'?'navbar-active':''">
                            Dashboard
                        </a>
                    </li>
                        <li class="hide-tab-device">
                            <a href="/historique" title="Historique" :class="page=='historique'?'navbar-active':''">
                                Historique
                            </a>
                        </li>
                        <!-- <li class="hide-tab-device">
                            <a href="#/Dashboards/BirdEyeView/Index" title="Birds Eye View" class="">
                                Birds Eye View
                            </a>
                        </li> -->
                </ul>
                <ul class="nav navbar-nav navbar-right navbar-right-custom top-header-navbar">

                        <li>
                            <a href="/geofence" title="Gestion des zones géofences" class="nav-icon">
                                <img src="/img/icon_geofence.png" style="width: 25px; margin-top: -16px;">
                            </a>
                        </li>

                        <li>
                            <a href="/compte" :class="page=='compte'?'navbar-active nav-icon':'nav-icon'" title="Mon compte" data-asidebar="ServiceMaintance-asidebar">
                                <img src="/img/icon_vehicle.png" style="width: 25px; margin-top: -10px;">
                            </a>
                        </li>

                        <li class="br">
                            <a href="/rapport" :class="page=='rapport'?'navbar-active nav-icon':'nav-icon'" title="Rapports">
                                <img src="/img/rapport.png" style="width: 25px; margin-top: -16px;">
                            </a>
                        </li>
                                            <li class="bl-white">
                            <div href="javascript:;" class="alert-col-dropdown dropdown">
                                <div class="dropdown-toggle" data-toggle="dropdown">
                                    <em class="icon icon-bell fs-17"></em>
                                    <div id="VehicleAlertTotalCountSpan" style="" class="label label-danger lbl-top">0</div>
                                </div>
                                <div class="dropdown-menu animated flipInX">
                                    <div id="VehicleAlertCountDiv">
    <ul class="list-inline">
        <li class="w-100per">
            <ul class="list-unstyled dropdown-menu-custom">
                    <li><a href="#"><img src="/img/ContinuesDriving2.png" alt="icon_img">Continuous Driving<span class="label label-gray pull-right">0</span></a></li>
                                                                                    <li><a href="#"><img src="/img/Ignition2.png" alt="icon_img">Ignition <span class="label label-gray pull-right">0</span></a></li>
                                                    <li><a href="#"><img src="/img/Overspeed2.png" alt="icon_img">Over Speed <span class="label label-gray pull-right">0</span></a></li>
                                                                                                                                                                                            </ul>
        </li>
    </ul>
</div>
                                    <div class="list-group-item cursor-default text-center">
                                        <div class="mb text-muted" id="VehicleAlertTotalCount0" style="display:none;">
                                            No alerts are available..!!
                                        </div>
                                        <div>
                                            <a href="#" class="btn btn-inverse btn-outline btn-sm btn-block">
                                                Show All
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                                            <li>
                            <a href="/admin" :class="page=='admin'?'navbar-active nav-icon':'nav-icon'" title="Admin">
                                <em class="icon icon-settings"></em>
                            </a>
                        </li>
                    <li>
                        <a href="javascript:;" data-toggle-state="offsidebar-open" data-no-persist="true">
                            <em class="icon icon-user"></em>
                        </a>
                    </li>
                </ul>

                <ul class="list-unstyled">


                    <li>
                        <a href="javascript:;" style="display:none" id="mapviewsidebar" data-toggle-state="offsidebar1-open" data-no-persist="true">
                            <em class="icon-equalizer"></em>
                        </a>
                    </li>

                    <li>
                        <a href="javascript:;" style="display:none" id="birdeyeviewsidebar" data-toggle-state="offsidebarBirdEye-open" data-no-persist="true">
                            <em class="icon-location-pin"></em>
                        </a>
                    </li>


                    <li>
                        <a href="javascript:;" style="display:none" id="mapviewsidebarpoi" data-toggle-state="offsidebarMapViewPoi-open" data-no-persist="true">
                            <em class="icon-location-pin"></em>
                        </a>
                    </li>


                    <li>
                        <a href="javascript:;" style="display:none" id="dashboardviewsidebar" data-toggle-state="offsidebarDashboard-open" data-no-persist="true">
                            <em class="icon-location-pin"></em>
                        </a>
                    </li>

                    <li>
                        <a href="javascript:;" style="display:none" id="directionsidebar" data-toggle-state="offsidebarDirection-open" data-no-persist="true">
                            <em class="icon-location-pin"></em>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;" style="display:none" id="mapviewsidebarpNearby" data-toggle-state="offsidebarNearby-open" data-no-persist="true">
                            <em class="icon-equalizer"></em>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
</header>


<aside class="offsidebar">
    <nav>
        <div role="tabpanel">
            <ul role="tablist" class="nav nav-tabs nav-justified">
                <li role="presentation">
                    <a href="#app-settings" aria-controls="app-settings" role="tab" data-toggle="tab">
                        <em class="icon-equalizer fa-lg"></em>
                    </a>
                </li>
                <li role="presentation" class="active">
                    <a href="#app-chat" aria-controls="app-chat" role="tab" data-toggle="tab">
                        <em class="icon-user fa-lg"></em>
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="app-settings" role="tabpanel" class="tab-pane fade">
                    <h3 class="text-center text-thin">Settings</h3>
                    <div class="p">
                        <h4 class="text-muted text-thin">Themes</h4>
                        <div class="table-grid mb">
                            <div class="col mb">
                                <div class="setting-color">
                                    <label data-load-css="/css/theme-success.css" onclick="UpdateUserTheme('0')">
                                        <input type="radio" name="setting-theme">
                                        <span class="icon-check"></span>
                                        <span class="split">
                                            <span class="color theme-success"></span>
                                            <span class="color theme-success"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col mb">
                                <div class="setting-color">
                                    <label data-load-css="/css/theme-primary.css" onclick="UpdateUserTheme('1')">
                                        <input type="radio" name="setting-theme">
                                        <span class="icon-check"></span>
                                        <span class="split">
                                            <span class="color theme-primary"></span>
                                            <span class="color theme-primary"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col mb">
                                <div class="setting-color">
                                    <label data-load-css="/css/theme-danger.css" onclick="UpdateUserTheme('2')">
                                        <input type="radio" name="setting-theme">
                                        <span class="icon-check"></span>
                                        <span class="split">
                                            <span class="color theme-danger"></span>
                                            <span class="color theme-danger"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col mb">
                                <div class="setting-color">
                                    <label data-load-css="/css/theme-warning.css" onclick="UpdateUserTheme('3')">
                                        <input type="radio" name="setting-theme">
                                        <span class="icon-check"></span>
                                        <span class="split">
                                            <span class="color theme-warning"></span>
                                            <span class="color theme-warning"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="table-grid mb">
                            <div class="col mb">
                                <div class="setting-color">
                                    <label data-load-css="/css/theme-purple.css" onclick="UpdateUserTheme('4')">
                                        <input type="radio" name="setting-theme">
                                        <span class="icon-check"></span>
                                        <span class="split">
                                            <span class="color theme-purple"></span>
                                            <span class="color theme-purple"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col mb">
                                <div class="setting-color">
                                    <label data-load-css="/css/theme-info.css" onclick="UpdateUserTheme('5')">
                                        <input type="radio" name="setting-theme" checked="">
                                        <span class="icon-check"></span>
                                        <span class="split">
                                            <span class="color theme-info"></span>
                                            <span class="color theme-info"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col mb">
                                <div class="setting-color">
                                    <label data-load-css="/css/theme-gray.css" onclick="UpdateUserTheme('6')">
                                        <input type="radio" name="setting-theme">
                                        <span class="icon-check"></span>
                                        <span class="split">
                                            <span class="color theme-gray"></span>
                                            <span class="color theme-gray"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col mb">
                                <div class="setting-color">
                                    <label data-load-css="/css/theme-black.css" onclick="UpdateUserTheme('7')">
                                        <input type="radio" name="setting-theme">
                                        <span class="icon-check"></span>
                                        <span class="split">
                                            <span class="color theme-black"></span>
                                            <span class="color theme-black"></span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p">
                        <h4 class="text-muted text-thin">Layout</h4>
                        <div class="clearfix">
                            <p class="pull-left">Fixed Header</p>
                            <div class="pull-right">
                                <label class="switch">
                                    <input id="chk-fixed" type="checkbox" data-toggle-state="layout-fixed">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <div class="clearfix">
                            <p class="pull-left">Boxed</p>
                            <div class="pull-right">
                                <label class="switch">
                                    <input id="chk-boxed" type="checkbox" data-toggle-state="layout-boxed">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        
                        <div class="clearfix">
                            <p class="pull-left">Popup Alert</p>
                            <div class="pull-right">
                                <label class="switch">
                                    <input id="chk-popup-alert" onclick="UpdatePopupAlert()" type="checkbox">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div id="app-chat" role="tabpanel" class="tab-pane fade in active">
                    <ul class="nav">
                        <li class="has-user-block overflow_inital">
                            <div>
                                <div class="item user-block">
                                    <!-- User picture-->
                                    <div class="user-block-picture">
                                        <div class="user-block-status">
                                            <img src="/img/user_default.png" alt="user_img" width="55" height="55" class="/img-circle">
                                            <div class="circle circle-success circle-lg"></div>
                                        </div>
                                    </div>
                                    <!-- Name and Job-->
                                        <div class="user-block-info" style="display:block;">
                                            <span class="user-block-name">
                                                <span>{{infoUser.prenom}} {{infoUser.nom}}</span>
                                            </span>
                                            <span class="user-block-role mt">
                                                <a href="javascript:;" onclick="_ChangePassword()" title="Change Password">Change Password</a>
                                            </span>
                                        </div>

                                    <div class="col-md-12">

                                        <div class="mt text-center dropdown lang_dropdown">
                                            <button id="lang_txt" class="btn dropdown-toggle" type="button" data-toggle="dropdown">
                                                English
                                                <span class="caret"></span>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                    <li><a href="javascript:;" class="lang_change" data-value="0">English</a></li>
                                                    <li><a href="javascript:;" class="lang_change" data-value="1">Español</a></li>
                                                    <li><a href="javascript:;" class="lang_change" data-value="2">عربى</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul class="nav bt">
                        <li>
                                <form method="get" action="/login" id="logoutForm">
                                    <div class="p-lg text-center">
                                        <a href="javascript:document.getElementById('logoutForm').submit()" class="btn btn-primary btn-square">
                                            Log Out
                                        </a>
                                    </div>
                                </form>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</aside>

</template>

<script setup>
var props=defineProps({infoUser:{},page:null});
if (location.host.includes("gts")) {
    document.title="Gts-Afrique Tracking système";
}else{
    document.title="Sunutrack Tracking système";
}
console.log(props.infoUser.entreprise);
</script>