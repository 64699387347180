<template>
   <header class="topnavbar  mtopnavbar navbar-fixed-top topnavbar-wrapper">
    <link href="/css/mobile-menu.css" rel="stylesheet">
    <link href="/css/responsive.css" rel="stylesheet">
            <div class="navbar-header brand-logo-bg">
                <button type="button" class="house_toggle navbar-toggle">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a href="#" class="navbar-brand">

                    <div class="brand-logo brand-logo-text" :title="infoUser.entreprise=='gts'?'GTS AFRIQUE PREMIUM':'SUNUTRACK PREMIUM'">
                        {{infoUser.entreprise=='gts'?"GTS AFRIQUE":"SUNUTRACK"}} PREMIUM
                    </div>
                </a>
            </div>

            <nav id="SideMenu" class="cd-dropdown visible-xs">

                <ul class="cd-dropdown-content Mobile_Nav list-unstyled">
                    <li class="close_postion"><a href="#0" class="cd-close">Close</a></li>
                    <li>
                        <div class="">
                            <div class="Side_menu_image">
                                <div class="overflow-hidden side_menu_pt pl pr">
                                    <div class="w-50per pull-left">
                                        <div class="side_menu_user_inital">
                                            {{infoUser.prenom?infoUser.prenom.substring(0,1).toLocaleUpperCase():""}}
                                        </div>
                                    </div>
                                    <div class="w-50per pull-left">
                                        <div class="text-right">
                                            <img src="/img/logo.png" width="135" height="54">
                                        </div>
                                    </div>
                                </div>
                                <div class="pl mt-lg pr">
                                    <p class="side_menu_user_name">{{infoUser.prenom}} {{infoUser.nom}}</p>
                                    <p class="side_menu_mail">{{infoUser.email}}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="hide-tab-device">
                        <a href="/" title="Temps Réel">
                            <i class="icon-grid mr-lg"></i>&nbsp; Temps Réel
                        </a>
                    </li>
                    <li class="hide-tab-device">
                        <a href="/historique_mobile" title="History">
                            <i class="icon-notebook mr-lg"></i>&nbsp; Historique
                        </a>
                    </li>
                    <li class="hide-tab-device">
                        <a href="/rapport_mobile" title="Reports">
                            <i class="icon-notebook mr-lg"></i>&nbsp; Rapport
                        </a>
                    </li>
                    <!-- <li>
                        <a href="javascript:;" style="display:none" id="mapviewsidebar" data-toggle-state="offsidebar1-open" data-no-persist="true">
                            <em class="icon-equalizer"></em>
                        </a>
                    </li> -->
                    <li>
                        <form method="get" action="/login" id="logoutForm">
                            <a href="javascript:document.getElementById('logoutForm').submit()" title="Log Out">
                                <i class="icon-power mr-lg"></i>&nbsp;
                                Se déconnecter
                            </a>
                        </form>
                    </li>
                </ul>
            </nav>
        </header>

</template>

<script setup>
import {onMounted} from 'vue'
import { useRoute,useRouter } from 'vue-router';
var props=defineProps({infoUser:{},page:null}),route = useRoute(),router= useRouter();
function goToHome() {
    router.push({
        name: 'Home',
        // query: { kisko:2  },
    })
}
function goToRapport() {
    router.push({
        name: 'rapport_kilo_mobile',
        // query: { kisko:2  },
    })
}
onMounted(() => {
    $(".cd-close").click(function () {
        $("#SideMenu").removeClass("dropdown-is-active");
    });
    // fin onMonted ========================================================================================================================
})
if (location.host.includes("gts")) {
    document.title="Gts-Afrique Tracking système";
}else{
    document.title="Sunutrack Tracking système";
}
console.log(props.infoUser);

</script>