import { createApp,defineCustomElement } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import signalBar from "./customElements/signalBar.ce.vue";
import './index.css'
import JsonExcel from "vue-json-excel3";
var kisko =2;
createApp(App).component("downloadExcel", JsonExcel).use(router).use(store).mount('#app')
customElements.define('signal-bar', 
defineCustomElement(signalBar)
);